<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add New Brand</h3>
            </div>
            <CardToolbar
              @submitEvent="onSubmit"
              back-text="Back to list"
              back-url="/brands/list"
              button-text="Brands"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <form class="form" id="brand_form">
              <div class="row">
                <div class="col-xl-2"></div>
                <div class="col-xl-8">
                  <div class="my-5">
                    <div class="form-group row">
                      <label class="col-3 font-weight-bolder">Name</label>
                      <div class="col-9">
                        <input
                          v-model="brand.name"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter brand name"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3 font-weight-bolder"
                        >Description</label
                      >
                      <div class="col-9">
                        <textarea
                          v-model="brand.description"
                          class="form-control form-control-solid"
                          rows="8"
                          placeholder="Enter brand description"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3 font-weight-bolder">Web</label>
                      <div class="col-9">
                        <input
                          v-model="brand.webUrl"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="Enter brand web"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-3 font-weight-bolder">Brand Logo</label>
                      <div class="col-9">
                        <vue2Dropzone
                          class="dropzone dropzone-default dropzone-primary dz-clickable"
                          ref="myVueDropzone"
                          @vdropzone-file-added="filesAdded"
                          @vdropzone-complete="complete"
                          @vdropzone-success="success"
                          id="dropzone"
                          :options="dropzoneOptions"
                          :useCustomSlot="true"
                        >
                          <div class="dropzone-msg dz-message needsclick">
                            <h3 class="dropzone-msg-title">
                              Drop brand logo here or click to upload.
                            </h3>
                            <span class="dropzone-msg-desc"
                              >.png format up to 500kb</span
                            >
                          </div>
                        </vue2Dropzone>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2"></div>
              </div>
            </form>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: 'NewBrand',
  components: {
    CardToolbar,
    vue2Dropzone
  },
  data() {
    return {
      brand: {
        name: null,
        description: null,
        webUrl: null,
        featuredImage: {
          src: '',
          alt: ''
        }
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 1
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Brands', route: '/brands/list' },
      { title: 'Add New' }
    ])
  },
  methods: {
    onSubmit() {
      this.postBrand(this.brand).then(({ status }) => {
        if (status === 201) {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Brand successful added to database'
          })

          setTimeout(() => {
            this.$router.push({ name: 'brands-list' })
          }, 500)
        }
      })
    },
    async postBrand(entity) {
      try {
        return await ApiService.post('/brands', entity)
      } catch (e) {
        throw new Error(e)
      }
    },
    filesAdded() {},
    success(_, response) {
      this.brand.featuredImage.src = '/uploads/' + response
    },
    complete() {}
  }
}
</script>
